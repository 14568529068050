import PropTypes from 'prop-types'
import React, { useState, useCallback } from 'react'
import { useSelector } from 'react-redux'
import Helmet from 'react-helmet'
import { ClearRefinements } from 'react-instantsearch-dom'

import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { breakPoints, colors } from '@constants/styles'
import ClearSvg from '@components/shared/svgs/clearSvg'
import CircleCounter from '@components/shared/circle-counter'
import SearchFilters from './search-filters'
import SearchSorting from './search-sorting'
import ConnectedStats from './connectors/ConnectedStats'
import ConnectedHitsLoadMore from './connectors/ConnectedHitsLoadMore'
import { SortFilter } from '../plp/search-results'

const LeftBarWrapper = styled.div`
  display: none;
  background-color: ${colors.white};
  height: fit-content;
  min-width: 333px;
  padding: 20px 18px;
  margin-right: 1rem;
  margin-top: 1rem;

  @media only screen and (min-width: ${breakPoints['large-max']}) {
    display: block;
  }
`

const Summary = styled(Grid)`
  padding: 0;
  margin-bottom: 20px;
  @media only screen and (max-width: ${breakPoints['large-max']}) {
    display: none;
  }
`

const SummaryTop = styled(Grid)`
  padding: 24px 20px 4px;
  @media only screen and (min-width: ${breakPoints['large-max']}) {
    display: none;
  }
`

const ClearAll = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  div > button {
    color: ${colors.primary};
    text-decoration: underline;
    font-size: 1rem;
  }
`

const SClearSvg = styled(ClearSvg)`
  cursor: pointer;
  circle {
    fill: #0053a0;
  }
`

const SearchMobileTitle = styled.span`
  height: 24px;
  width: 108px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
`

const Wrapper = styled.div`
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    display: flex;
    margin-top: 20px;
  }
`
const SidebarMobileWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 333px;
  background-color: #f6f6f6;
  padding: 20px;
`

const MobileHeaderWrapper = styled(Grid)`
  margin-bottom: 24px;
`

const SidebarMobileModalWrapper = styled.div`
  display: ${({ openModalSidebar }) => (openModalSidebar ? 'block' : 'none')};
  position: fixed;
  z-index: 4000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
`

const SearchResults = props => {
  const { displayFilters, matchPath, searchState } = props
  const isMobile = useSelector(state => state.global.isMobile)
  const [openModalSidebar, setOpenModalSidebar] = useState(false)

  const countFilters = useCallback(
    () =>
      Object.values(searchState.refinementList || {}).reduce((acc, next) => {
        if (next instanceof Array) {
          return acc + next.length
        }

        return next ? acc + 1 : acc
      }, 0),
    [searchState],
  )

  const filtersQuantity = countFilters()

  const closeModal = useCallback(e => {
    const isOutsideModal = Array.from(e.target.classList)
      .join(' ')
      .includes('SidebarMobileModalWrapper')

    if (isOutsideModal) {
      setOpenModalSidebar(false)
    }
  }, [])

  return (
    <div className="search-results">
      {matchPath.includes('/search') && (
        <>
          <Helmet title="Search Results - Rooms To Go" />
          <h1 className="home-heading">Search Results</h1>
        </>
      )}
      <Wrapper>
        {/* Filters + Stats */}
        <SummaryTop container justify="space-between" alignItems="center" p="20px">
          <ConnectedStats absolutePosition={false} />
          <SortFilter onClick={() => setOpenModalSidebar(true)}>
            <span>Sort &amp; Filter</span>
            {filtersQuantity > 0 && <CircleCounter quantity={filtersQuantity} />}
          </SortFilter>
        </SummaryTop>

        {isMobile && (
          <SidebarMobileModalWrapper openModalSidebar={openModalSidebar} onClick={closeModal}>
            <SidebarMobileWrapper>
              <MobileHeaderWrapper container justify="space-between" alignItems="center">
                <button
                  type="button"
                  title="Clear the search query."
                  hidden=""
                  onClick={() => setOpenModalSidebar(false)}
                  tabIndex={0}
                >
                  <SClearSvg />
                </button>
                <SearchMobileTitle>Sort &amp; Filter</SearchMobileTitle>
                {JSON.stringify(searchState.refinementList) !== '{}' && (
                  <ClearAll>
                    <ClearRefinements translations={{ reset: 'Clear all' }} />
                  </ClearAll>
                )}
              </MobileHeaderWrapper>
              <SearchSorting />
              <SearchFilters attributes={displayFilters} matchPath={matchPath} />
            </SidebarMobileWrapper>
          </SidebarMobileModalWrapper>
        )}

        {/* DESKTOP */}
        <LeftBarWrapper>
          <Summary container justify="space-between" alignItems="center">
            <ConnectedStats absolutePosition={false} />
            {JSON.stringify(searchState.refinementList) !== '{}' && (
              <ClearAll>
                <ClearRefinements translations={{ reset: 'Clear all' }} />
              </ClearAll>
            )}
          </Summary>
          <SearchSorting />
          <SearchFilters attributes={displayFilters} matchPath={matchPath} />
        </LeftBarWrapper>

        <Grid container>
          <ConnectedHitsLoadMore />
        </Grid>
      </Wrapper>
    </div>
  )
}

SearchResults.propTypes = {
  displayFilters: PropTypes.any,
  matchPath: PropTypes.string,
  searchState: PropTypes.object,
}

export default SearchResults
