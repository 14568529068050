import React, { useEffect, useState } from 'react'
import { useQueryClient } from 'react-query'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import { connectInfiniteHits, connectStateResults } from 'react-instantsearch-dom'
import { LoadingSpinner } from '@components/shared/LoadingSpinner'
import '@assets/css/components/search/connectors/infinite-hits.sass'
import loaderLight from '@assets/images/loader-light.svg'
import Hit from './Hit'
import LoadMorePagination from '../../plp/plp-parts/LoadMorePagination'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const HitsWrapper = styled.div`
  animation: ${fadeIn} 500ms ease-out forwards;
`
function trackProductImpressions({ hits }) {
  if (window) {
    const productSkus = hits.map(hit => hit.sku)
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'ee_impression', ecommerce: { impressions: productSkus } })
    }
  }
}

function trackSearch({ indexName, queryID }) {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'algolia_query',
      algoliaIndexName: indexName, // the userToken of the current visitor to uniquely identify them
      algoliaQueryID: queryID,
    })
  }
}

export function getQueryPlpKey(i) {
  return ['plp-results', i]
}

const LoadingSpinnerWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  background: rgba(0, 0, 0, 0.2);
  > div {
    position: sticky;
    top: 50%;
    height: 100px;
    width: 100px;
    margin: 0 auto 100px;
  }
`

const ConnectedHits = React.memo(function ConnectedHits({ hits, searchResults, hasMore, refineNext, searchState }) {
  const queryClient = useQueryClient()

  const restoredState = queryClient.getQueryData(getQueryPlpKey('search-query'))
  const restoredScrollTo = queryClient.getQueryData(getQueryPlpKey('scrollTo'))
  const [initialState, setInitialState] = useState([])
  const [loading, setLoading] = useState(false)
  const refinedHits = restoredState?.hits || initialState?.hits || []
  const refinedLength = restoredState?.hits?.length || initialState?.hits?.length

  const searchNextState = () => {
    refineNext()

    queryClient.prefetchQuery({
      queryKey: getQueryPlpKey('currentPage'),
      queryFn: () => searchState?.page + 1,
    })
  }

  const getHitWithId = hit => {
    const hitExist = hits?.find(i => i.objectID === hit.objectID)
    if (hitExist) {
      return { ...hit, ...hitExist }
    }
    return hit
  }

  useEffect(() => {
    if (!initialState?.hits) {
      const hitsWithId = searchResults?.hits?.map(getHitWithId)
      const newSearchResults = { ...searchResults, hits: hitsWithId }
      setInitialState(newSearchResults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults?.hits])

  useEffect(() => {
    trackProductImpressions({ hits })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!searchResults?.query) {
      return
    }
    trackSearch({
      indexName: searchResults.index || restoredState?.index,
      queryID: searchResults.queryID || restoredState?.queryID,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults?.query])

  useEffect(() => {
    if (!searchState.page || !searchResults) {
      return
    }

    if (searchState?.page <= 1) {
      const hitsWithId = searchResults?.hits?.map(getHitWithId)
      const newSearchResults = { ...searchResults, hits: hitsWithId }
      queryClient.prefetchQuery({
        queryKey: getQueryPlpKey('search-query'),
        queryFn: () => newSearchResults,
      })
    }

    const hitsAmount = restoredState?.hits?.length || 0
    const currentPage = searchState?.page
    if (hitsAmount < currentPage * 20 && hitsAmount < searchResults?.nbHits) {
      let hitsNew = restoredState?.hits || initialState?.hits || []
      if (searchResults?.hits) {
        const hitsWithId = searchResults?.hits?.map(getHitWithId)
        hitsNew = [...hitsNew, ...hitsWithId]
      }

      const result = {
        ...searchResults,
        hits: hitsNew,
      }

      queryClient.prefetchQuery({
        queryKey: getQueryPlpKey('search-query'),
        queryFn: () => result,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults])

  useEffect(() => {
    if (restoredScrollTo) {
      setLoading(true)
    }

    if (searchResults && restoredScrollTo) {
      window.setTimeout(() => {
        window.scrollTo({
          top: restoredScrollTo,
          behavior: 'smooth',
        })
        setLoading(false)
      }, 0)

      queryClient.prefetchQuery({
        queryKey: getQueryPlpKey('scrollTo'),
        queryFn: () => null,
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults])

  return (
    <div id="productResultsWrapper" role="region" aria-label="Product Results" tabIndex="-1" className="search-wrapper">
      {refinedHits?.length > 40 && loading && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner alt="Submitting Synchrony credit card" src={loaderLight} />
        </LoadingSpinnerWrapper>
      )}
      {!!refinedHits?.length && !!searchResults && (
        <div className="ais-InfiniteHits">
          <HitsWrapper className="ais-InfiniteHits-list grid-x grid-margin-x grid-margin-y">
            {refinedHits?.map((tile, index) => (
              <Hit key={tile.sku} hit={tile} index={index} />
            ))}
          </HitsWrapper>
        </div>
      )}
      {!!refinedHits?.length && hasMore && (
        <LoadMorePagination
          onLoadMore={searchNextState}
          currentItems={refinedLength}
          totalItems={searchResults?.nbHits}
        />
      )}
    </div>
  )
})

ConnectedHits.propTypes = {
  hits: PropTypes.array,
  searchResults: PropTypes.object,
  hasMore: PropTypes.bool,
  refineNext: PropTypes.func,
  searchState: PropTypes.objectOf(PropTypes.any),
}

ConnectedHits.displayName = 'ConnectedHits'

export default connectInfiniteHits(connectStateResults(ConnectedHits))
