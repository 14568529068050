import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connectHits, connectStateResults } from 'react-instantsearch-dom'
import Hits from './Hits'
import '@assets/css/components/search/connectors/infinite-hits.sass'

function trackProductImpressions({ hits }) {
  if (window) {
    const productSkus = hits.map(hit => hit.sku)
    if (window.dataLayer) {
      window.dataLayer.push({ event: 'ee_impression', ecommerce: { impressions: productSkus } })
    }
  }
}

function trackSearch({ indexName, queryID }) {
  if (window && window.dataLayer) {
    window.dataLayer.push({
      event: 'algolia_query',
      algoliaIndexName: indexName, // the userToken of the current visitor to uniquely identify them
      algoliaQueryID: queryID,
    })
  }
}

const ConnectedHits = React.memo(
  function ConnectedHits({ hits, searchResults }) {
    useEffect(() => {
      trackProductImpressions({ hits })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      if (!searchResults?.query) {
        return
      }
      trackSearch({ indexName: searchResults.index, queryID: searchResults.queryID })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchResults?.query])
    return (
      <div
        id="productResultsWrapper"
        role="region"
        aria-label="Product Results"
        tabIndex="-1"
        className="search-wrapper"
      >
        <div className="ais-InfiniteHits">
          <Hits hits={hits} />
        </div>
      </div>
    )
  },
  ({ hits }, { hits: nextHits }) => hits === nextHits,
)
ConnectedHits.propTypes = {
  hits: PropTypes.any,
  searchResults: PropTypes.any,
}

ConnectedHits.displayName = 'ConnectedHits'

export default connectHits(connectStateResults(ConnectedHits))
