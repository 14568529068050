import React, { useRef, useState, useEffect } from 'react'
import { node, func } from 'prop-types'
import { useQueryClient } from 'react-query'
import { InstantSearch, Configure } from 'react-instantsearch-dom'
import { isEqual } from 'lodash'
import { getRegionZone } from '@helpers/geo-location'
import { useLocation } from '@gatsbyjs/reach-router'
import algoliasearch from 'algoliasearch/lite'
import qs from 'qs'
import { Number } from 'core-js'
import { getSessionId } from '@helpers/cloudfront'

const DEBOUNCE_TIME = 700
const algoliaClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_API_KEY)
const searchClient = {
  /*
    Only make the request for hits if we are on the search page. If the user is on a page with a Contentful Search Query,
    the InstanceSearch router in the `search-query` page will be used instead.
  */
  search(requests) {
    // eslint-disable-next-line react/destructuring-assignment
    if (typeof window !== 'undefined' && requests.find(r => !!r.params.query)) {
      return algoliaClient.search(requests)
    }
    return null
  },
}

const createURL = state => {
  delete state.configure
  if (state.refinementList) {
    const { refinementList, ...newState } = state

    Object.keys(refinementList).forEach(key => {
      const value = refinementList[key]
      if (value) {
        newState[key] = value
      }
    })

    return `?${qs.stringify(newState, { arrayFormat: 'repeat' })}`
  }
  return `?${qs.stringify(state)}`
}

const searchStateToUrl = searchState => {
  let url = ''
  if (searchState) {
    url = `/search/${createURL(searchState)}&filters=true`
  }
  return url
}

const urlToSearchState = ({ search }) => {
  const state = {}
  if (search) {
    const { page, query, configure, sortBy, filters, ...refinementList } = qs.parse(search.slice(1))
    state.page = page ? Number.parseInt(page) : 1
    state.query = query
    state.configure = configure || {}
    state.configure.enableRules = false
    state.configure.query = query
    state.refinementList = refinementList

    if (sortBy) {
      state.sortBy = sortBy
    }
  }

  return state
}

const windowPush = (state, url) => {
  if (!window.location.href.endsWith(url)) {
    window.history.pushState(state, document.title, url)
  }
}

const getInitialSearchState = location => {
  let initialSearchState = {}
  if (location) {
    initialSearchState = urlToSearchState(location)
  }
  return initialSearchState
}

function getQueryPlpKey(i) {
  return ['plp-results', i]
}

const InstantSearchRouter = ({ children, setGlobalSearchState }) => {
  const location = useLocation()
  const setStateId = useRef()
  const queryClient = useQueryClient()
  const restoredPage = queryClient.getQueryData(getQueryPlpKey('currentPage'))
  const [searchState, setSearchState] = useState({})
  const { region, zone } = getRegionZone()
  const isLoadMoreAbEnabled = typeof window !== 'undefined' ? sessionStorage.getItem('isLoadMoreAbEnabled') : false

  useEffect(() => {
    const nextState = getInitialSearchState(location)
    if (isLoadMoreAbEnabled) {
      nextState.page = restoredPage ?? nextState.page
    }
    if (!isEqual(searchState, nextState)) {
      setSearchState(nextState)
      setGlobalSearchState(nextState)
    }
    // eslint-disable-next-line
  }, [location])

  useEffect(() => {
    clearTimeout(setStateId.current)
  }, [])

  const onSearchStateChange = nextSearchState => {
    if (nextSearchState.configure && !isEqual(searchState, nextSearchState)) {
      const searchUrlState = { ...nextSearchState }
      if (isLoadMoreAbEnabled) {
        delete searchUrlState.page
      }
      const searchURL = searchStateToUrl(searchUrlState)

      if (searchURL !== window.location.pathname) {
        if (!isLoadMoreAbEnabled) {
          window.scrollTo(0, 0)
        }
      }
      clearTimeout(setStateId.current)

      setStateId.current = setTimeout(() => {
        windowPush(nextSearchState, searchURL)
      }, DEBOUNCE_TIME)
      setSearchState(nextSearchState)
      setGlobalSearchState(nextSearchState)
    }
  }

  const sessionId = getSessionId()

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={`${region === 'PR' ? '' : 'search-'}${region}-${process.env.GATSBY_ENV_SHORT}`}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      <Configure
        query={searchState.query}
        clickAnalytics
        userToken={sessionId}
        attributesToRetrieve={[
          'addon_items',
          'availabilityDate',
          'availableDateTimestamp',
          'breadcrumb',
          'color',
          'customer_assembly_required',
          'delivery_type',
          'variations',
          'variation_keys',
          'default_price',
          'default_price_range',
          'sku',
          'tileIndex',
          'title_slug',
          'on_promotion',
          'promotion_name',
          'inStock',
          'isAvailable',
          'items_in_room',
          'title',
          'primary_image',
          'grid_image',
          'grid_image_room',
          'primary_image_item',
          'alternate_images',
          'special_offer',
          'on_promotion',
          'strikethrough',
          'shipping_cost_code',
          'free_shipping',
          'route',
          'closeout',
          'saleFlag',
          'swatch_request_flag',
          `zone_${zone}_on_sale`,
          `zone_${zone}_sale_price_price_range`,
          `zone_${zone}_list_price`,
          `zone_${zone}_sale_price`,
          `zone_${zone}_list_price_price_range`,
          `upgrade_items`,
          `category`,
        ]}
      />
      {children}
    </InstantSearch>
  )
}

export default InstantSearchRouter

InstantSearchRouter.propTypes = {
  children: node,
  setGlobalSearchState: func,
}
