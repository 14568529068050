import PropTypes from 'prop-types'
import React from 'react'
import RTGLink from '@shared/link'
import rtgTo from '@helpers/rtg-to'
import { useSelector } from 'react-redux'

const FilterLink = ({ item, children, createURL, refine, toggleFilter }) => {
  const isMobile = useSelector(state => state.global.isMobile)
  const link = createURL(item.value)
  return (
    <RTGLink
      role="checkbox"
      aria-checked={item.isRefined}
      onKeyPress={e => {
        if (e.keyCode === 32) {
          e.preventDefault()
          e.target.click()
        }
      }}
      onClick={e => {
        e.preventDefault()
        if (!isMobile) toggleFilter()
        refine(item.value)
      }}
      data={{
        rel: 'noopener noreferrer',
        [rtgTo(link)]: link,
        category: 'plp parts',
        action: 'filter link click',
        label: 'filter link',
      }}
    >
      {children}
    </RTGLink>
  )
}

FilterLink.propTypes = {
  children: PropTypes.any,
  createURL: PropTypes.func,
  item: PropTypes.shape({
    isRefined: PropTypes.any,
    value: PropTypes.any,
  }),
  refine: PropTypes.func,
  toggleFilter: PropTypes.func,
}

export default FilterLink
