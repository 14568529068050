import PropTypes from 'prop-types'
import React, { useState, useRef } from 'react'
import { useQueryClient } from 'react-query'
import { connectRefinementList } from 'react-instantsearch-dom'
import styled from 'styled-components'
import { breakPoints } from '@constants/styles'
import { titleCase } from '@helpers/string-helper'
import useOutsideClick from '@helpers/hooks/useOutsideClick'
import FilterLink from '../filter-link'
import Accordion, { summaryWithCounter } from '../../plp/plp-parts/Accordion'

const FilterOuterWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  white-space: nowrap;
  :last-child {
    .MuiAccordionSummary-root {
      margin: 0;
    }
    .MuiAccordionSummary-root.Mui-expanded {
      margin: 0 0 10px 0;
    }
    .MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
    .MuiCollapse-wrapperInner {
      > div > div > div a:last-child > div {
        margin: 0;
      }
    }
  }

  @media screen and (min-width: ${breakPoints['large-min']}) {
    & > :not(:first-child) {
      display: none;
    }
  }
`

const FilterDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FilterItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin: 0 0 12px 0;

  @media screen and (max-width: ${breakPoints.small}) {
    margin: 0.75rem 0;
  }
`

const FilterItemText = styled.span`
  margin-left: 10px;
  color: #333;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 20px;
`

const Checkbox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  vertical-align: sub;
  border: 1px solid #585860;
  width: 26px;
  height: 26px;
  margin-right: 9.5px;

  background-color: ${({ attributeLabel, itemLabel }) => (attributeLabel === 'color' ? itemLabel : '#ffffff')};

  &:hover {
    border: 0.1rem solid #0053a0;
  }

  ${({ isApplied }) =>
    isApplied &&
    `
  & {
    background: #0053a0;
    border: none;
  } 

  `}
`

const ColorBox = styled.div`
  width: 26px;
  height: 26px;
  margin-right: 5.5px;
  border: 1px solid #000;
  background-color: ${({ itemLabel }) => itemLabel};
  border-radius: 5px;
`

const FilterItemResults = styled.span`
  margin-left: 10px;
  color: #474747;
  font-size: 0.85rem;
`
function getQueryPlpKey(i) {
  return ['plp-results', i]
}

const ConnectedRefinementList = ({ items, attribute, key, refine, createURL, ...rest }) => {
  const [isActive, setIsActive] = useState(false)
  const wrapperRef = useRef(null)
  const queryClient = useQueryClient()
  useOutsideClick(wrapperRef, () => setIsActive(false))

  const toggleFilter = forcedIsActive => {
    if (typeof forcedIsActive !== 'undefined') {
      setIsActive(forcedIsActive)
    }
    queryClient.prefetchQuery({
      queryKey: getQueryPlpKey('currentPage'),
      queryFn: () => undefined,
    })

    queryClient.prefetchQuery({
      queryKey: getQueryPlpKey('search-query'),
      queryFn: () => [],
    })

    setIsActive(!isActive)
  }

  // TODO wut this do
  const splitters = [' ', ',', '-']
  const attributeLabel =
    !attribute.includes('size') && attribute.indexOf('_family') !== -1
      ? attribute.substring(0, attribute.indexOf('_family'))
      : titleCase(attribute.split('_').join(' '))

  if (items?.length > 1) {
    const amount = items.reduce((prev, next) => (next?.isRefined ? prev + 1 : prev), 0)
    return (
      <FilterOuterWrapper key={key} ref={wrapperRef}>
        <Accordion summary={summaryWithCounter(titleCase(attributeLabel, splitters), amount)}>
          <FilterDetailsWrapper>
            {items.map(
              item =>
                !item.label.includes(',') && (
                  <FilterLink
                    item={item}
                    key={item.label}
                    createURL={createURL}
                    refine={refine}
                    toggleFilter={toggleFilter}
                  >
                    <FilterItemWrapper>
                      <Checkbox isApplied={item.isRefined} itemLabel={item.label}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#fff"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                        </svg>
                      </Checkbox>
                      {attributeLabel === 'color' && (
                        <ColorBox attributeLabel={attributeLabel} itemLabel={item.label} />
                      )}
                      <FilterItemText>{titleCase(item.label, splitters).replace(/,/g, ', ')}</FilterItemText>
                      <FilterItemResults>({item.count})</FilterItemResults>
                    </FilterItemWrapper>
                  </FilterLink>
                ),
            )}
          </FilterDetailsWrapper>
        </Accordion>
      </FilterOuterWrapper>
    )
  }
  return null
}

ConnectedRefinementList.propTypes = {
  attribute: PropTypes.string,
  createURL: PropTypes.any,
  items: PropTypes.array,
  key: PropTypes.any,
  refine: PropTypes.any,
}

export default connectRefinementList(ConnectedRefinementList)
