import React, { useRef } from 'react'
import { useQueryClient } from 'react-query'
import styled from 'styled-components'
import classNames from 'classnames'
import { objectOf, any, number } from 'prop-types'
import { connectHitInsights } from 'react-instantsearch-dom'
import insightsClient from 'search-insights'
import ProductTile from '../../product/product-tile'
import { getQueryPlpKey } from './ConnectedHitsLoadMore'
import '@assets/css/components/search/connectors/infinite-hits.sass'

/* Tile grid breakpoints */
const colBreakpoints = {
  TWO_COL: '750px',
  FOUR_COL: '1500px',
}

/* These values came from the styles that were being applied by the 'large-3, medium-6, small-12 foundation grid classes. */
const tileOuterSpacing = '1.875rem'
const tileWidth = {
  SMALL: '100%',
  MEDIUM: '50%',
  LARGE: '25%',
}

const TileWrapper = styled.div`
  width: calc(${tileWidth.SMALL} - ${tileOuterSpacing}) !important;
  @media print, screen and (min-width: ${colBreakpoints.TWO_COL}) {
    width: calc(${tileWidth.MEDIUM} - ${tileOuterSpacing}) !important;
  }
`

insightsClient('init', {
  appId: process.env.GATSBY_ALGOLIA_APP_ID,
  apiKey: process.env.GATSBY_ALGOLIA_API_KEY,
})

const Hit = React.memo(
  ({ hit, insights, index }) => {
    const queryClient = useQueryClient()
    const hitRef = useRef(null)

    const handleCaptureClick = () => {
      insights('clickedObjectIDsAfterSearch', {
        eventName: 'Product Clicked',
      })

      if (hitRef) {
        const { offsetTop } = hitRef.current
        // WE need to subtract navigation height (140)
        const moveTo = offsetTop - 140

        queryClient.prefetchQuery({
          queryKey: getQueryPlpKey('scrollTo'),
          queryFn: () => moveTo,
        })
      }
    }

    return (
      <TileWrapper
        ref={hitRef}
        key={hit.sku || hit.tileIndex}
        className={classNames('ais-InfiniteHits-item cell')}
        onClickCapture={handleCaptureClick}
      >
        <ProductTile data={hit} index={index} viewType="grid" source="search" last={index === hit.__position} />
      </TileWrapper>
    )
  },
  ({ hit, index }, { hit: nextHit, index: nextIndex }) =>
    index === nextIndex || JSON.stringify(hit) === JSON.stringify(nextHit),
)

Hit.displayName = 'Hit'
Hit.propTypes = {
  hit: objectOf(any),
  insights: any,
  index: number.isRequired,
}

export default connectHitInsights(insightsClient)(Hit)
