import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { setupAnalytics } from '@helpers/google-tag-manager'
import Layout from '../components/layout'
import SearchResults from '../components/searchv2/search-results'
import SearchResultsLoadMore from '../components/searchv2/search-results-load-more'

import InstantSearchRouter from '../components/shared/instant-search-router'
import QueryRuleCustomData from '../components/shared/query-rule-custom-data'
import '../assets/css/pages/search.sass'

const displayFacets = ['brand', 'color_family', 'category', 'material_family', 'style']

const SearchPage = ({ pageContext, ...restProps }) => {
  const [pagePath, setPagePath] = useState('/search')
  const isLoadMoreAbEnabled = typeof window !== 'undefined' ? sessionStorage.getItem('isLoadMoreAbEnabled') : false
  const [searchState, setSearchState] = useState({})

  useEffect(() => {
    const path = pageContext?.matchPath ? pageContext.matchPath.replace(/\*/g, '') : '/search'
    setupAnalytics({ pageData: { type: 'search', title: 'Search', path } })

    setPagePath(path)
  }, [pageContext])
  const Implementation = isLoadMoreAbEnabled ? SearchResultsLoadMore : SearchResults

  return (
    <Layout pageContext={pageContext} {...restProps}>
      <Helmet meta={[{ name: 'robots', content: 'noindex, nofollow' }]} />

      <InstantSearchRouter setGlobalSearchState={setSearchState} pageContext={pageContext} {...restProps}>
        <QueryRuleCustomData />
        <Implementation
          searchState={searchState}
          displayFilters={displayFacets}
          matchPath={pagePath}
          gridWidth={2}
          source="search"
        />
      </InstantSearchRouter>
    </Layout>
  )
}
SearchPage.propTypes = {
  pageContext: PropTypes.shape({
    matchPath: PropTypes.string,
  }),
}

export default SearchPage
