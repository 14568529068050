import React from 'react'
import { connectSortBy } from 'react-instantsearch-dom'
import '../../../assets/css/components/search/connectors/sort-by.sass'
import { addToDataLayer } from '@helpers/google-tag-manager'
import Accordion, { textSummary } from '../../plp/plp-parts/Accordion'
import RadioButtonsGroup from '../../plp/plp-parts/RadioButtonsGroup'

export default connectSortBy(({ items, refine, uniqueNameForId, currentRefinement }) => {
  const handleChange = (_, value) => {
    addToDataLayer('click', 'plp', 'sort change', value)
    refine(value)
  }

  return (
    <>
      <Accordion summary={textSummary('Sort')} id={uniqueNameForId}>
        <RadioButtonsGroup onChange={handleChange} value={currentRefinement} items={items} />
      </Accordion>
    </>
  )
})
