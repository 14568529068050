import React from 'react'
import { QueryRuleCustomData } from 'react-instantsearch-dom'
import { navigate } from 'gatsby'

const QueryRule = () => (
  <QueryRuleCustomData
    transformItems={items => {
      const match = items.find(dataOut => Boolean(dataOut.redirect_url))
      if (match && match.redirect_url && typeof window !== 'undefined') {
        navigate(match.redirect_url)
      }
      return []
    }}
  >
    {() => null}
  </QueryRuleCustomData>
)

export default QueryRule
