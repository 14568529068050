import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connectStats, connectStateResults } from 'react-instantsearch-dom'
import '../../../assets/css/components/search/connectors/stats.sass'
import { announce } from '@helpers/aria-announce'
import styled, { css } from 'styled-components'
import { breakPoints } from '@constants/styles'

const StatsWrapper = styled.div`
  ${({ absolutePosition }) =>
    absolutePosition &&
    css`
      width: 100%;
    `}

  display: flex;
  justify-content: center;
  pointer-events: none;

  ${({ absolutePosition }) =>
    absolutePosition &&
    css`
      @media screen and (min-width: ${breakPoints['large-min']}) {
        position: absolute;
      }
    `}
`

export const ConnectStats = ({ nbHits, searching, absolutePosition = true }) => {
  useEffect(() => {
    announce(`${nbHits.toLocaleString()} RESULTS`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {}, [searching, nbHits])

  const resultsText = nbHits > 0 ? `${nbHits.toLocaleString()} Results` : `Sorry, no results found.`

  return (
    <StatsWrapper absolutePosition={absolutePosition}>
      <span id="searchResultsCount">{searching ? <div>Loading...</div> : <p>{resultsText}</p>}</span>
    </StatsWrapper>
  )
}

ConnectStats.propTypes = {
  nbHits: PropTypes.number,
  searching: PropTypes.any,
  absolutePosition: PropTypes.bool,
}

export default connectStats(connectStateResults(ConnectStats))
