import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import Hit from './Hit'
import '@assets/css/components/search/connectors/infinite-hits.sass'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const HitsWrapper = styled.div`
  animation: ${fadeIn} 500ms ease-out forwards;
`

const Hits = React.memo(({ hits }) => (
  <HitsWrapper className="ais-InfiniteHits-list grid-x grid-margin-x grid-margin-y">
    {hits.map((tile, index) => (
      <Hit key={tile.sku} hit={tile} index={index} />
    ))}
  </HitsWrapper>
))
Hits.propTypes = {
  hits: PropTypes.any,
}

Hits.displayName = 'Hits'

export default Hits
